.rpb-photo-gallery {
  overflow: hidden;
  .col.photo-gallery-photo{
    max-height: 33vw;
  }

  .gallery-lightbox {
    .photo-gallery-photo {
      &:hover {
        cursor:pointer;
      }
      img {
        height:auto;
      }
    }
  }
}
