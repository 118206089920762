.site-footer {
	img {
		width:auto;
		max-height:80px;
	}
	.footer-main {
		i,svg {
			min-width:30px;
			color:white;
		}
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding-bottom:7.5px;
			&:last-of-type {
				padding-bottom:0;
			}
			a {
				color: white;
				text-decoration: none;
				font-weight: normal;
			}
		}
	}
	.gform_wrapper {
		.gform_description {
			margin-bottom:1rem;
			display:block;
		}
		.gfield_required_text {
			color:white;
		}
	}
	.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
		background:white;
		border:4px solid white;
		color:$brand-primary;
		&:hover {
			background:lighten($brand-primary,15%);
			border:4px solid lighten($brand-primary,15%);
			color:white;
		}
	}	
}
ul#menu-footer-first-menu {
    padding-left: 0px;
    margin-top: 30px;

	li {
		list-style: none;
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 15px;
		text-transform: uppercase;
		font-size: 14px;

		a {
			color: #fff;
			font-weight: normal;
		}
	}
}
#gform_2 {
	position: relative;

	.gform_footer.top_label {
		position: absolute;
		height: 30px;
		width: 50px;
		top: 38px;
		right: 0px;
	}
	input#gform_submit_button_2 {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0px;
		right: 0px;
		line-height: 0;
		background: transparent;
		border: transparent;
		color: #000;
		font-size: 23px;
	}
}
.white-label span.gform_description {
    color: #fff;
    margin-bottom: 10px;
    display: inline-block;
}