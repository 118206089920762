.single-post, .category {
  .blog-post-content {
    .sub-heading {
      width: calc(100% - 50px);
    }
    .blog-featured-image {
      margin-bottom:1rem;
      border-radius:6px;
      /*@include media-breakpoint-up(lg) {
        float:right;
        max-width:45%;
        margin-left:1rem;
      }*/
    }
  }
}

.date-block {
  width: 100px;
  margin-top: -30px;
  box-shadow: 0px 8px 3px -5px rgba(0, 0, 0, 0.25);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  background-color: $dark;

  &:before {
    content: "";
    height: 15px;
    width: 15px;
    position: absolute;
    background: darken($green, 25%);
    left: -15px;
    top: 0px;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }
  &:after {
    content: "";
    height: 15px;
    width: 15px;
    position: absolute;
    background: darken($green, 25%);
    right: -14px;
    top: 0px;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
  }
}

.circle-link {
  color: $dark;
  border-radius: 100%;
  display: inline-flex!important;
  justify-content: center;
  align-items: center;
  border: double 5px;
  height: 40px;
  width: 40px;
  opacity: .5;
}

.blog-page-postcontent {
  &:hover {
    cursor:pointer;
  }
}
p.byline.author.vcard.fw-bold {
  display: none;
}