.gmap {
    // box-shadow: -11px 15px 16px -10px rgb(0 0 0 / 15%);
    height: 750px;
    width: 100%;
    border-radius: 20px;
}
.pac-container {
	color: #c55a5a; 
    border-bottom-right-radius: 18px;
    border-bottom-left-radius: 18px;
    border: none;
    background-color: #F5F5F5;
    padding: 0.75rem 1.5rem;
    margin-top: 5px;
    box-shadow: -11px 15px 16px -10px rgb(0 0 0 / 15%);

}
textarea:focus, input:focus{
    outline: none;
}

/*
class for each line of the result
*/
.pac-item {
	font-size: 12px;
	color: #002a77;
	font-family: Arial, Helvetica, sans-serif;
}
.location_name{
    color: #1f67cd;
    font-weight: 600;
    font-size: 1.477rem;
}
.tab.collapsed {
    .location_name{
        font-weight: 400;
        color: black;
    }
}
.row_container{
    padding-right:8vw;
    @include media-breakpoint-down(lg) {
        padding-right:0;
      }
}
.left_container{
    margin-right:1rem;
    @include media-breakpoint-down(lg) {
        margin-right:0;
      }
}
.search_container{
    padding-left:8vw;
    @include media-breakpoint-down(lg) {
        padding-left:0;
      }
      #searchTextField{
        border-radius: 18px;
        border: none;
        background-color: #F5F5F5;
        padding: 0.75rem 1.5rem;
        text-align:center;
        transition: border 2s ease-in-out;
     }
     #searchTextField:focus{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
     }
}
.nothing_container{
    padding-left:8vw;
    color: #1359ba;
    @include media-breakpoint-down(lg) {
        padding-left:0;
      }
}
.all_loc_container{
    padding-left:8vw;
    text-align:center;
    margin: 1rem 0;
    @include media-breakpoint-down(lg) {
        padding-left:0;
    }
    button{
        width: fit-content;
    }
}
.list_container{
    height:623.5px;
    ul{
        padding-left:8vw;
        border-radius: 20px;
        margin: 0;
        @include media-breakpoint-down(lg) {
            padding-left:0;
            padding-right:0;
        }
        .loc_item{
            color:black;
            border-radius: 20px;
            background-color:#F5F5F5;
            border:none;
            &:hover{
                color:black;
                .location_name{
                    color: #1f67cd;
                }
            }
        }
    }
    
}
.my_location{
    text-align: center;
    padding-left: 4px;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
}
.list-btn{
    &:hover{
        background-color: black;
        color: white;
    }
}
.shade {
    content: '';
    display: block;
    position: absolute;
    width: 370px;
    height: 72px;
    background-image: linear-gradient(to top, rgb(245 245 245 / 0%), #f5f5f5);
    pointer-events: none;
}

ul::-webkit-scrollbar {
    display: none;
}

.gm-style iframe+div {
    border: none!important;
}

.gm-style-iw+div {
    display: none;
}

button.gm-ui-hover-effect {
    visibility: hidden;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
    color: white;
    background-color: $brand-primary;
    transition-duration: 30ms;
    -webkit-transition-duration: 30ms;
    &:hover {
        text-decoration: none;
        cursor: pointer;
        font-size: 14px;
        line-height: 1.42857;
        background-color: #1f67cd;
        color: white;
        transform: scale(1.2);
        box-shadow: -1px -1px 13px 0px rgb(0 0 0 / 30%);
    }
}

.map_btn {
    background: none padding-box rgb(255, 255, 255);
    display: table-cell;
    border: 0px;
    margin: 0px;
    padding: 0px 17px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
    direction: ltr;
    overflow: hidden;
    text-align: center;
    height: 40px;
    vertical-align: middle;
    color: rgb(86, 86, 86);
    font-family: Roboto, Arial, sans-serif;
    font-size: 18px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    min-width: 66px;
    margin: 10px;
    &:hover {
        background: none padding-box rgb(235, 235, 235);
        color: rgb(0, 0, 0);
    }
}