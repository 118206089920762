body {
  background: $light;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

// Accessibility Plugin Adjustments

#pojo-a11y-toolbar {
  height: 100%;
  top: 0!important;
}

.pojo-a11y-toolbar-toggle {
  bottom: 0!important;
}

.pojo-a11y-toolbar-overlay {
  position: absolute!important;
  bottom: 0!important;
  right: 0!important;
}

/** custom classes */
.absolute-right {
  position: absolute;
  right: 0px;
}
.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 50vw;
  @include media-breakpoint-up(md) {
    min-height: 33vw;
  }
  @include media-breakpoint-up(lg) {
    min-height: 350px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.square {
  padding-top: 100%;
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.light-box-shadow {
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
}

.min-height-smaller {
  min-height: 33vh;
  @include media-breakpoint-up(md) {
    min-height: 300px;
  }
}

.min-height-md-down {
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
}

.pad-top-100 {
  padding-top: 100%!important;
}

.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;
}

.large-icon * {
  font-size: 2.5em;
  @include media-breakpoint-up(md) {
    font-size: 3em;
  }
  @include media-breakpoint-up(md) {
    font-size: 4em;
  }
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}
.dark-bkg-before:before {
  content: '';
  height: 8vw;
  margin-top: calc(-8vw + 1px);
  background: $dark;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  width: 100%;
  display: block;
}
.stars-bkg {
  background: url(/images/stars.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px 0px;
}
.stars-bkg-right-after {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 150px;
    width: 150px;
    background: url(/images/stars.png);
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    top: 30px;
    right: 30px;
  }
}
.stars-bkg-right-lg-after {
  @include media-breakpoint-up(lg) {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 300px;
      width: 300px;
      background: url(/images/stars.png);
      background-size: contain;
      background-position: top right;
      background-repeat: no-repeat;
      top: -10vw;
      right: 30px;
    }
  }
}
.stars-bkg-after {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: 150px;
    width: 150px;
    background: url(/images/stars.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    opacity: .8;
  }
}
.diagonal-after {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width:100%;
    background: url(/images/angle-transparent.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom left;
    opacity: .45;
    top: 0px;
    left:0px;
    z-index: 1;
  }
  .hc-content {
    z-index: 2;
    position: relative;
  }
}
.diagonal-blur {
  position: relative;

  @include media-breakpoint-down(lg) {
    min-height: 350px;
  }
  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: url(/images/angle-transparent.png);
    background-repeat: no-repeat;
    background-size: 100% 50%;
    opacity: 0.45;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-position: bottom;
  }
  .hc-content {
    z-index: 2;
    position: relative;
  }
}
.circle {
  padding: 0;
  border-radius: 1000px;
  z-index: 2;
  border: solid 5px $brand-primary;

  &.small-bio {
    padding: 30px;
    border: solid 5px #fff;
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}
.negative-bottom {
  margin-bottom: -100px;
  @include media-breakpoint-up(md) {
    margin-bottom: -150px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: -175px;
  }
}

.negative-top-header {
  margin-top: -47px;
  @include media-breakpoint-up(md) {
    margin-top: -115px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -130px;
  }
}

.clip-top {
  clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}

.clip-oblique {
  clip-path: polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
  @include media-breakpoint-up(lg) {
    clip-path: polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
  }
}

.border-bottom-trans-white {
  border-bottom: solid 1px rgba(255,255,255,.1);
}

.box-shadow {
  box-shadow: 0 0 30px rgba(0,0,0,0.1);
}

.box-shadow-small {
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
// Images

.img-wrap {
  img {
    max-height: 75px;
    margin: 10px;
  }
}

img {
  max-width: 100%;
}

.img-on-dark-bg {
  filter: brightness(0) invert(1);
}
// Util

.absolute-link {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
  top: 0;
  left: 0;
}

.opacity-soft {
  opacity: 0.97;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-85 {
  opacity: 0.85;
}

.half-opacity {
  opacity: 0.5;
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}

.z-index-100 {
  z-index:100;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}
