/** Search form */
.search-form {
  @extend .row;
}

.search-form label {
  @extend .mb-3;

  font-weight: normal;
}

.search-form .search-field {
  @extend .form-control;
}

.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

/* Gravity Forms Styling */

.gform_legacy_markup_wrapper textarea.large {
  @include media-breakpoint-up(md) {
    height:150px!important;
  }
}

.white-label {
  label, span.gform_descriptiond {
    color: #fff!important;
  }
}
input#gform_submit_button_1 {
  background: #222;
  border-radius: 5px;
  color: #fff;
  padding: 15px 30px;
  overflow: hidden;
  border: 0px;
}
.submit-primary {
  input[type="submit"] {
    background-color: $brand-primary!important;
    color:$dark!important;
  }
}