/*
** Custom animations
*/

// CSS only background image fade slider
@keyframes bg-fade-out {
  33% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
}