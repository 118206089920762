.btn {
  a {
    color: #fff !important;
  }

  &:hover {
    background: $brand-primary;
  }
}

button {
  &:hover {
    cursor:pointer;
  }
  &:focus {
    outline: 0;
  }
}

.btn.btn-primary,
.btn.primary-button,
.btn.secondary-button .cta.nav-item a,
.primary-btn,
.primary-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.gform_legacy_markup_wrapper .gform_footer input.button,
.gform_legacy_markup_wrapper .gform_footer input[type="submit"],
.gform_legacy_markup_wrapper .gform_page_footer input.button,
.gform_legacy_markup_wrapper .gform_page_footer input[type="submit"],
.gform_wrapper.gravity-theme .gform_footer button,
.gform_wrapper.gravity-theme .gform_footer input,
.gform_wrapper.gravity-theme .gform_page_footer button,
.gform_wrapper.gravity-theme .gform_page_footer input {
  display: inline-block;
  padding: 0.75rem 3rem 0.75rem 1.5rem;
  border: 0px!important;
  border-radius: 5px;
  background: $brand-primary;
  color: $dark;
  transition-duration: 350ms;
  line-height: 1;
  font-size: .9rem;
  letter-spacing: .1em;
  text-align: center;
  font-family: $subheading-font;
  font-weight: $medium-weight;
  text-transform: uppercase;
  font-style: italic;
  position: relative;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color:$dark;
    .theme-button-icon {
      transform: translateX(5px);
    }
  }
  &:after {
    content: "→";
    position: absolute;
    right: 1rem;
    top: 7px;
    bottom: auto;
    margin: auto;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    border: solid 1px rgba(0,0,0,.2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  &.primary-btn-transparent {
    background: transparent;
    border: 4px solid $brand-primary;
    color: $brand-primary;

    &:hover {
      background: $brand-primary;
      border: 4px solid $brand-primary;
      color: white;
    }
  }

  &.light {
    background: linear-gradient(148deg, #efefef 50%, $light);
    color: #222 !important;
    text-shadow: none;
  }

  &.accent-btn {
    background: $accent;
    color: $dark !important;
    text-shadow: 0;
    border: 0px;
  }

  &.dark-btn {
    background: $dark;
    color: $accent!important;
    text-shadow: 0;
    border:0px;

    &:hover {
      background:lighten($dark,15%);
    }
  }

  &.inactive-btn {
    color:$inactive;
    &:hover {
      color:lighten($inactive,10%);
    }
  }

  &.secondary {
    background: $brand-primary;
    color: #fff !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);

    a[href^="tel"] {
      color: #fff !important;
    }

    i {
      color: #fff !important;
    }
  }

  &.white-btn {
    background: #fff;
    color: $dark !important;
    border:0!important;

    &:hover {
      border:0 !important;
      color: white !important;
      background:$dark !important;
    }
  }

  &.top-margin {
    margin-top: 15px;
  }

  &.no-arrow {
    padding: 0.5em 1em 0.5em 1em!important !important;

    &:after {
      display: none;
    }
  }

  &.smaller {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }
}

.cta.nav-item a {
  padding: 0 15px;
  margin-top: 8px;

  &:after {
    display: none;
  }
}

.secondary-btn,
.secondary-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background: $brand-primary;
  color: white;
  text-shadow: 1px 1px 0 darken($brand-primary, 5%);
  text-transform: uppercase;
  border-radius: 2px;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;
  text-decoration: none;
  -webkit-box-shadow: 2px 2px 0 1px darken($brand-primary, 10%);
  box-shadow: 2px 2px 0 1px darken($brand-primary, 10%);
  font-weight: 700;
  @include media-breakpoint-up(lg) {
    padding: 10px 25px;
    font-size: 18px;
  }

  &:hover {
    opacity: 0.8;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
}

.primary-btn,
.primary-button,
.secondary-btn,
.secondary-button {
  &.small {
    font-size: 14px !important;
    padding: 0.5em 0.75em 0.5em 0.75em !important;
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }
}

.button-shadow {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
}

button.btn.btn-primary.btn-xs[data-target="#secondaryGeos"] {
  display: block;
  width: 100%;
  border: 0;
}

div#secondaryGeos {
  background: $accent;
  text-align: center;
  outline: 0 !important;

  h4 {
    display: inline-block;
    margin: 10px;
    color: #fff;
    padding: 5px;
    background: rgba(255, 255, 255, 0.1);
    font-size: 15px;
  }
}

a.arrow-btn {
  color: $dark !important;
  font-family: $subheading-font;
  font-weight: bold;
  transition: all 0.2s ease-out;
  display: inline-block;
  min-height: 51px !important;
  background: transparent;
  text-transform: uppercase;
  border: 0px;

  span {
    display: inline-block;
    transition: all 0.2s ease-out;
  }

  &:before {
    content: "→";
    height: 30px;
    width: 30px;
    border: solid 1px;
    border-radius: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    color: #000;
    background: rgba(0, 0, 0, 0);
    transition: all 0.2s ease-out;
    @include media-breakpoint-up(md) {
      height: 50px;
      width: 50px;
    }
  }
  &.primary {
    &:before {
      background: linear-gradient($brand-primary, #620101);
      border-color: $brand-primary;
      transition: all 0.2s ease-out;
    }
  }
  &:hover {
    transition: all 0.2s ease-out;
    span {
      transform: translateX(10px);
      transition: all 0.2s ease-out;
    }
    &:before {
      background: linear-gradient($dark, #000);
      border-color: $dark;
      transition: all 0.2s ease-out;
      margin-right: 25px;
      color: #fff;
    }
  }
  &.white-btn {
    color: #fff !important;

    &:before {
      background: transparent;
      color: #fff;
      border-color: #fff;
    }
    &:hover {
      &:before {
        background: #fff;
        color: #000;
      }
    }
  }
}
