.age-gate-modal-backdrop-image {
  background-size: cover !important;
  background-position: center center !important;

  &:before {
    content: '';
    background: rgba(0,0,0,0.5);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
  }
}
.age-gate-modal.show:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}
.modal-background-video {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

.age-gate-modal-backdrop-video.show {
  opacity:1!important;
}

.age-gate-modal {
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  z-index:999999;
  .modal-content {
    border:0px;
    background-color: transparent;

    .modal-title {
      font-size: 36px;
    }
  }

  .age-gate-modal-logo {
    text-align: center;
    img {
      max-width: 65%;
      height:auto;
    }
  }

  .h5 {
    font-weight: bold;
  }

  .modal-header {
    border: 0;
  }

  .modal-footer {
    border: 0;
    font-family: $paragraph-font;
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
    font-family: $paragraph-font;
  }

  .age-gate-denied-body {
    display: none;
  }
}
